import { default as _91slug_93buOrU94tTBMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93W4t1jd9d0eMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93TmTXX5LRj9Meta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91paxid_93NrWcySGDS1Meta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue?macro=true";
import { default as _91id_93x59Wd0FtXCMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_932WlMq7gcXqMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_93tFIdUJiTt2Meta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91tab_93VeTp3Pt3lgMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue?macro=true";
import { default as _91tab_9388OyihTkAaMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue?macro=true";
import { default as _91tab_93lznhyQUCAcMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue?macro=true";
import { default as _91slug_93tcPf4JLIxaMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91id_93bwNnNZdKpJMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93ZQWAAdSdipMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue?macro=true";
import { default as _91slug_93pX2w5o6NYYMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91slug_93s7U9rCAvtLMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue?macro=true";
import { default as _91ocr_93DnNjVZTqkGMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93bET30lSsyqMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93bRk0DNW0d9Meta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_938OW3MyCJXZMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91slug_93Su36H1jPfgMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue?macro=true";
import { default as _91jwt_93IPaUYQtmbUMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93zMYhtSg5mZMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as index68lIKFtd38Meta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as callbacknPAga5zSXtMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue?macro=true";
import { default as blackweekZGFpy79kETMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue?macro=true";
import { default as _91slug_93sOIrzgufdKMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue?macro=true";
import { default as indexbV5sf904j0Meta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue?macro=true";
import { default as bookcallN40Koj3ppJMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookcall.vue?macro=true";
import { default as byebyeieLtVk3Vhz3oMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as index7elP3lEeSgMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue?macro=true";
import { default as lastminuteFReewypj6pMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue?macro=true";
import { default as _91name_93Fk82SbBSzoMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue?macro=true";
import { default as indexXSRD6UTimxMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue?macro=true";
import { default as cataloguetMljFhWp2WMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue?macro=true";
import { default as complaintXhbPjDkeW7Meta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as dinbokning_45aktuellVNw1UWvdxwMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as _91slug_93F7ce4jZiP1Meta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue?macro=true";
import { default as index2SLY5Iy1iYMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue?macro=true";
import { default as faqaaut8SAOVlMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as _91id_93hd6CHR97woMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indexpZpFEH28AYMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as _91name_93cVEjVVOsj0Meta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexQmD8Fe0KubMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexZkEB8scplKMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue?macro=true";
import { default as grouptravelSz7Q3aOcl3Meta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue?macro=true";
import { default as indexgqZmNfEZYxMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue?macro=true";
import { default as lastminute60Itg4rwimMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue?macro=true";
import { default as map9FofinPitmMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue?macro=true";
import { default as dinbokningwdGxANWq9bMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletterhEURQZa8MfMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlineMV30NvxesyMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as failedTEF8jAY6wWMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as indexLoj1K2pWiTMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as searchWvOs7ARxrGMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexk2LlucnI6XMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45donex6R1pNjIEAMeta } from "/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reiseleder/:id()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/reiseplan",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/cruiseskip",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "satisfactionstatus-triptypeid",
    path: "/tilfredshet-status/:triptypeid()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinreise",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info-lectures",
    path: "/info/forelesninger",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "info-connections",
    path: "/info/anslutning",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-about",
    path: "/info/om",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-slug",
    path: "/destination/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "competition",
    path: "/konkurranse",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "category",
    path: "/kategori",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/bestill/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "auth",
    path: "/medlem",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93zMYhtSg5mZMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "auth-slug",
    path: "/medlem/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "auth-callback",
    path: "/medlem/callback",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue")
  },
  {
    name: "blackweek",
    path: "/blackweek",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue")
  },
  {
    name: "blog-slug",
    path: "/blogg/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blogg",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue")
  },
  {
    name: "book-tripid-step",
    path: "/bestill/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "bookcall",
    path: "/boka-samtal",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookcall.vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "calendar",
    path: "/kalender",
    meta: index7elP3lEeSgMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue")
  },
  {
    name: "calendar-lastminute",
    path: "/kalender/restplasser",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue")
  },
  {
    name: "campaign-name",
    path: "/kampanje/:name()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign",
    path: "/kampanje",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue")
  },
  {
    name: "catalogue",
    path: "/katalog",
    meta: cataloguetMljFhWp2WMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue")
  },
  {
    name: "category-slug",
    path: "/kategori/:slug()",
    meta: _91slug_93s7U9rCAvtLMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "competition-slug",
    path: "/konkurranse/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamasjon",
    meta: complaintXhbPjDkeW7Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-slug-tab",
    path: "/destination/:slug()/:tab()",
    meta: _91tab_93ZQWAAdSdipMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "event-slug",
    path: "/arrangement/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue")
  },
  {
    name: "event",
    path: "/arrangement",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/gavekort/:id()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/gavekort",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hoteller/:name()",
    meta: _91name_93cVEjVVOsj0Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hoteller",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93tcPf4JLIxaMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "info-about-tab",
    path: "/info/om/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info-connections-tab",
    path: "/info/anslutning/:tab()",
    meta: _91tab_9388OyihTkAaMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-grouptravel",
    path: "/info/gruppereiser",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue")
  },
  {
    name: "info-lectures-tab",
    path: "/info/forelesninger/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "lastminute",
    path: "/restplasser-tilbud",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue")
  },
  {
    name: "map",
    path: "/kart",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinreise/:tab()",
    meta: _91tab_93tFIdUJiTt2Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_932WlMq7gcXqMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/evaluere-reisen",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "satisfactionstatus-triptypeid-paxid",
    path: "/tilfredshet-status/:triptypeid()/:paxid()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/cruiseskip/:name()",
    meta: _91name_93TmTXX5LRj9Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/reiseplan/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reiseleder/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reiseleder",
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45donex6R1pNjIEAMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-03-29-1440/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]